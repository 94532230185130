import './App.css';
import React, {useState} from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Form2 from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {saveAs} from 'file-saver';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {Formik, FieldArray, Field} from 'formik';

import './App.css';

let version = '1.6.2';

let NTP = ["0.pool.ntp.org", "1.pool.ntp.org", "2.pool.ntp.org", "3.pool.ntp.org"];

let ntpOptions = [];

for (let i in NTP) {
    ntpOptions.push({"value": NTP[i], "label": NTP[i]})
}

let OTA = {"host": "https://ota.atmotech.co/config.json", "settings": "0000000;21:00"};
let MQTT = {"host": "mqtt.atmotech.co", "port": 8883, "protocol": "mqtt-tls"};
let LEDS = {
    "voc": ["power", "network", "co2", "pm25", "", "voc", "t", "h"],
    "voc_ch2o": ["power", "network", "co2", "pm25", "ch2o", "voc", "t", "h"],
    "voc_index_ch2o": ["power", "network", "co2", "pm25", "ch2o", "voc_index", "t", "h"]
};
let LEVELS = {
    "voc": [
        {"start": 0, "end": 0.261, "color": "#00ff00"},
        {"start": 0.261, "end": 2.2, "color": "#ffff00"},
        {"start": 2.2, "end": 60, "color": "#ff0000"}
    ],
    "voc_index": [
        {"start": 0, "end": 200, "color": "#00ff00"},
        {"start": 200, "end": 350, "color": "#ffff00"},
        {"start": 350, "end": 500, "color": "#ff0000"}
    ],
    "co2": [
        {"start": 0, "end": 999, "color": "#00ff00"},
        {"start": 999, "end": 2499, "color": "#ffff00"},
        {"start": 2499, "end": 5000, "color": "#ff0000"}
    ],
    "pm25": [
        {"start": 0, "end": 50, "color": "#00ff00"},
        {"start": 50, "end": 140, "color": "#ffff00"},
        {"start": 140, "end": 1000, "color": "#ff0000"}
    ],
    "t": [
        {"start": 0, "end": 11, "color": "#ff0000"},
        {"start": 11, "end": 20, "color": "#ffff00"},
        {"start": 20, "end": 29, "color": "#00ff00"},
        {"start": 29, "end": 39, "color": "#ffff00"},
        {"start": 39, "end": 125, "color": "#ff0000"}
    ],
    "h": [
        {"start": 0, "end": 15, "color": "#ff0000"},
        {"start": 15, "end": 35, "color": "#ffff00"},
        {"start": 35, "end": 65, "color": "#00ff00"},
        {"start": 65, "end": 87, "color": "#ffff00"},
        {"start": 87, "end": 100, "color": "#ff0000"}
    ],
    "ch2o": [
        {"start": 0, "end": 0.1, "color": "#00ff00"},
        {"start": 0.1, "end": 0.75, "color": "#ffff00"},
        {"start": 0.75, "end": 5, "color": "#ff0000"}
    ],
    "co": [
        {"start": 0, "end": 8.7, "color": "#00ff00"},
        {"start": 8.7, "end": 15, "color": "#ffff00"},
        {"start": 15, "end": 500, "color": "#ff0000"}
    ],
    "o3": [
        {"start": 0, "end": 0.060, "color": "#00ff00"},
        {"start": 0.060, "end": 0.100, "color": "#ffff00"},
        {"start": 0.100, "end": 10, "color": "#ff0000"}
    ]
};
let WIFI_ENCRYPTION = [
    {label: "Open", value: 'open'},
    {label: "WPA2/WPA3", value: 'wpa2_3'},
    {label: "WPA2-Enterprise", value: 'wpa2-enterprise'},
    {label: "WPA3-Enterprise", value: 'wpa3-enterprise'},
];
let EAP_METHOD = [
    {label: "TLS", value: 'TLS'},
    {label: "PEAP", value: 'PEAP'},
    {label: "TTLS", value: 'TTLS'}
];
let TTLS_PHASE2 = [
    {label: "EAP", value: 'EAP'},
    {label: "MSCHAP", value: 'MSCHAP'},
    {label: "MSCHAPV2", value: 'MSCHAPV2'},
    {label: "PAP", value: 'PAP'},
    {label: "CHAP", value: 'CHAP'}
];
let CONFIGS = {
    'no-lan': {'lan': false},
    'lan': {'lan': true}
};
let CUBE_CONFIGS_BASIC = [
    {label: "with PoE (B2-E, BF2-E, BFCO2-E)", value: 'lan'},
    {label: "without PoE (BW, B1, BF1, BFCO1, BF2)", value: 'no-lan'}
];
let CUBE_CONFIGS_TVOC = [
    {label: "TVOC index", value: 'tvoc-index'},
    {label: "TVOC ppm", value: 'tvoc-ppm'}
];
let MQTT_OPTIONS = [
    {label: "MQTT TLS", value: 'mqtt-tls'},
    {label: "MQTT TCP", value: 'mqtt-tcp'}
];
let DHCP_OPTIONS = [
    {label: "DHCP", value: 'auto'},
    {label: "Static IP", value: 'static'}
];
let RS485_OPTIONS = [
    {label: "Modbus RTU", value: 'modbus'}
];
let RS485_BAUD_RATE_OPTIONS = [
    {label: "1200", value: '1200'},
    {label: "2400", value: '2400'},
    {label: "4800", value: '4800'},
    {label: "9600", value: '9600'},
    {label: "19200", value: '19200'},
    {label: "38400", value: '38400'},
    {label: "57600", value: '57600'},
    {label: "115200", value: '115200'}
];
let RS485_DATA_BITS_OPTIONS = [
    {label: "7", value: '7'},
    {label: "8", value: '8'}
];
let RS485_STOP_BITS_OPTIONS = [
    {label: "1", value: '1'},
    {label: "2", value: '2'}
];
let RS485_PARITY_OPTIONS = [
    {label: "none", value: 'none'},
    {label: "odd", value: 'odd'},
    {label: "even", value: 'even'}
];
let CUBE_POSITIONS = [
    {label: "Wall", value: 'wall'},
    {label: "Table", value: 'table'},
    {label: "Auto", value: 'auto'}
];

function App() {

    const [usbEnabled, handleUsbEnabled] = useState(true);
    const [usbDataStorageEnabled, handleUsbDataStorageEnabled] = useState(true);
    const [mainEnabled, handleMainEnabled] = useState(true);
    const [bleEnabled, handleBleEnabled] = useState(true);
    const [productionMQTTEnabled, handleProductionMQTTEnabled] = useState(true);
    const [productionOTAEnabled, handleProductionOTAEnabled] = useState(true);
    const [wifiEnabled, handleWifiEnabled] = useState(true);
    const [rs485Enabled, handleRS485Enabled] = useState(false);
    const [mqttEnabled, handleMQTTEnabled] = useState(true);
    const [rs485Option, handleRS485Option] = useState(RS485_OPTIONS[0].value);
    const [rs485BaudRate, handleRS485BaudRate] = useState(RS485_BAUD_RATE_OPTIONS[7].value);
    const [rs485DataBits, handleRS485DataBits] = useState(RS485_DATA_BITS_OPTIONS[1].value);
    const [rs485StopBits, handleRS485StopBits] = useState(RS485_STOP_BITS_OPTIONS[0].value);
    const [rs485Parity, handleRS485Parity] = useState(RS485_PARITY_OPTIONS[0].value);
    const [MQTTType, handleMQTTType] = useState(MQTT_OPTIONS[0].value);
    const [wifiDHCP, handleWifiDHCP] = useState(DHCP_OPTIONS[0].value);
    const [lanDHCP, handleLanDHCP] = useState(DHCP_OPTIONS[0].value);
    const [lanEnabled, handleLanEnabled] = useState(true);
    const [tabKey, setTabKey] = useState('wifi');
    const [ntp, handleNtp] = useState(ntpOptions);
    const [modbusIPEnabled, handleModbusIPEnabled] = useState(false);
    const [ledArray, handleLedArray] = useState(LEDS['voc_index_ch2o']);
    const [customLedEnabled, handleCustomLedEnabled] = useState(false);
    const [VOCtype, handleVOCtype] = useState(CUBE_CONFIGS_TVOC[0].value);
    const [ch2oEnabled, handleCh2oEnabled] = useState(true);
    const [wifiEncryption, handleWifiEncryption] = useState(WIFI_ENCRYPTION[1].value);
    const [validateCAEnabled, handleValidateCAEnabled] = useState(true);
    const [eapMethod, handleEapMethod] = useState(EAP_METHOD[0].value);
    const [ttlsPhase2, handleTtlsPhase2] = useState(TTLS_PHASE2[0].value);
    const [wpa3_192Enabled, handleWpa3_192Enabled] = useState(false);
    const [compensationEnabled, handleCompensationEnabled] = useState(false);
    const [cubePosition, handleCubePosition] = useState(CUBE_POSITIONS[0].value);

    const configLED = function (VOCtype, ch2oEnabled) {
        var model = 'voc';
        if (VOCtype === 'tvoc-index') {
            model = 'voc_index_ch2o';
        } else if (ch2oEnabled) {
            model = 'voc_ch2o';
        } else if (ch2oEnabled) {
            model = 'voc';
        }
        handleLedArray(LEDS[model]);
    }

    const generateConfig = function (values) {
        let config = {};
        if (wifiEnabled) {
            config['wifi'] = {'ssid': values.wifi_ssid};
            if (wifiEncryption === 'wpa2_3') {
                config['wifi']['password'] = values.wifi_password;
            } else if (wifiEncryption === 'wpa2-enterprise' || wifiEncryption === 'wpa3-enterprise') {
                let e = {};
                if (wifiEncryption === 'wpa3-enterprise') {
                    if (wpa3_192Enabled) {
                        e['enterprise_configuration'] = "WPA3_192BIT";
                    } else {
                        e['enterprise_configuration'] = "WPA3";
                    }
                } else {
                    e['enterprise_configuration'] = "WPA2";
                }
                if (wifiEncryption === 'wpa2-enterprise') {
                    e['validate_ca'] = validateCAEnabled;
                }
                e['eap_id'] = values.eap_id;
                if (validateCAEnabled || wifiEncryption === 'wpa3-enterprise') {
                    e['ca_file'] = values.wpa_ca;
                }
                e['eap_method'] = eapMethod;
                if (eapMethod === 'TLS') {
                    e['crt_file'] = values.wpa_crt;
                    e['key_file'] = values.wpa_key;
                    if (values.wpa_key_password) {
                        e['key_password'] = values.wpa_key_password;
                    }
                } else {
                    e['username'] = values.wpa_username;
                    e['password'] = values.wpa_password;
                    if (eapMethod === 'TTLS') {
                        e['ttls_phase2_method'] = ttlsPhase2;
                    }
                }
                config['wifi']['wpa_enterprise'] = e;
            }
            if (wifiDHCP === 'static') {
                config['wifi']['mode'] = 'static';
                config['wifi']['address'] = values.wifi_ip;
                config['wifi']['subnet'] = values.wifi_mask;
                config['wifi']['gateway'] = values.wifi_gateway;
                config['wifi']['dns1'] = values.wifi_dns1;
                config['wifi']['dns2'] = values.wifi_dns2;
            } else {
                config['wifi']['mode'] = 'auto';
            }
        } else {
            config['wifi'] = {'enabled': false};
        }
        if (lanEnabled) {
            config['lan'] = {};
            if (lanDHCP === 'static') {
                config['lan']['mode'] = 'static';
                config['lan']['address'] = values.lan_ip;
                config['lan']['subnet'] = values.lan_mask;
                config['lan']['gateway'] = values.lan_gateway;
                config['lan']['dns1'] = values.lan_dns1;
                config['lan']['dns2'] = values.lan_dns2;
            } else {
                config['lan']['mode'] = 'auto';
            }
        }
        let ntpArray = [];
        for (let i in ntp) {
            ntpArray.push(ntp[i].value)
        }
        config['ntp'] = ntpArray;
        config['options'] = {};
        config['options']['usb_enabled'] = usbEnabled;
        config['options']['usb_data_storage_enabled'] = usbDataStorageEnabled;
        config['options']['main_btn_reset_enabled'] = mainEnabled;
        config['options']['ble_adv_enabled'] = bleEnabled;
        if (mqttEnabled) {
            if (productionMQTTEnabled) {
                config['mqtt'] = MQTT;
            } else {
                config['mqtt'] = {'host': values.mqtt_host, 'port': values.mqtt_port, 'protocol': MQTTType};
                if (MQTTType === 'mqtt-tcp') {
                    config['mqtt']['username'] = values.mqtt_username;
                    config['mqtt']['password'] = values.mqtt_password;
                } else {
                    config['mqtt']['ca'] = values.mqtt_ca;
                    config['mqtt']['cert'] = values.mqtt_cert;
                    config['mqtt']['key'] = values.mqtt_key;
                }
            }
        } else {
            config['mqtt'] = {'enabled': false};
        }
        if (productionOTAEnabled) {
            config['ota'] = OTA;
        } else {
            config['ota'] = {'host': values.ota_url};
        }
        let settings = '';
        settings += values.day1 ? '1' : '0';
        settings += values.day2 ? '1' : '0';
        settings += values.day3 ? '1' : '0';
        settings += values.day4 ? '1' : '0';
        settings += values.day5 ? '1' : '0';
        settings += values.day6 ? '1' : '0';
        settings += values.day7 ? '1' : '0';
        settings += ';';
        settings += values.ota_time;
        config['ota']['settings'] = settings;
        if (rs485Enabled) {
            config['rs485'] = {'enabled': true, 'mode': rs485Option};
            if (rs485Option === 'modbus') {
                config['rs485']['modbus'] = {
                    'id': values.modbus_id,
                    'baud_rate': parseInt(rs485BaudRate),
                    'data_bits': parseInt(rs485DataBits),
                    'stop_bits': parseInt(rs485StopBits),
                    'parity': rs485Parity
                };
            }
        } else {
            config['rs485'] = {'enabled': false};
        }
        if (modbusIPEnabled) {
            config['modbus_ip'] = {'enabled': true, 'port': values.modbus_ip_port};
        } else {
            config['modbus_ip'] = {'enabled': false};
        }
        if (compensationEnabled) {
            config['corrections'] = {
                'position': cubePosition,
                't_correction': parseFloat(values.temp_correction),
                'h_correction': parseFloat(values.hum_correction)
            };
        }
        if (customLedEnabled) {
            let led_levels = {};
            for (let k in ledArray) {
                let key = ledArray[k];
                if (!(key in LEVELS)) {
                    continue
                }
                led_levels[key] = {"colors": []};
                for (let i = 0; i < 10; i++) {
                    let start = document.getElementsByName(`${key}.${i}.start`)[0];
                    if (!start) {
                        break;
                    } else {
                        let end = document.getElementsByName(`${key}.${i}.end`)[0];
                        let color = document.getElementsByName(`${key}.${i}.color`)[0];
                        led_levels[key]["colors"].push([parseFloat(start.value), parseFloat(end.value), color.value.substring(1)]);
                    }
                }
            }
            config['levels'] = led_levels;
            config['leds'] = ledArray;
        }
        let file = new File([JSON.stringify({"config": config})], "config.json", {type: "application/json;charset=utf-8"});
        //console.log(JSON.stringify({"config": config}));
        saveAs(file);
    };

    const handleNTPChange = function (newValue, actionMeta) {
        handleNtp(newValue);
    };

    const testIPV4 = function (ip) {
        return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i.test(ip);
    };

    const testMask = function (mask) {
        return /^((128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(((0|128|192|224|240|248|252|254)\.0\.0)|(255\.(((0|128|192|224|240|248|252|254)\.0)|255\.(0|128|192|224|240|248|252|254)))))$/.test(mask);
    };

    const testTime = function (time) {
        return /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(time);
    };

    let ledValues = function () {
        return ledArray.map((value) => {
            return value === 'network' || value === 'power' || value === '' ? '' : <tr>
                <td>{value}</td>
                <td>
                    <Formik
                        initialValues={LEVELS}
                        validateOnChange={true}
                        validate={values => {
                            let errors = {};
                            for (let i in values[value]) {
                                let lastStart = values[value][i].start;
                                let lastEnd = values[value][i].end;
                                if (lastEnd <= lastStart) {
                                    errors[`${value}.${i}.end`] = `Must be greater than ${lastStart}`;
                                }
                                if (i > 0) {
                                    if (values[value][i].start !== values[value][i - 1].end) {
                                        errors[`${value}.${i}.start`] = `Must be equal to ${values[value][i - 1].end}`;
                                    }
                                }
                            }
                            return errors;
                        }}>
                        {({values, errors}) => (
                                <FieldArray name={`${value}`}>
                                    {({insert, remove, push}) => (
                                        <div>
                                            <Table bordered>
                                                <tbody>
                                                {value in values && values[value].length > 0 && values[value].map((item, index) => (
                                                        <tr key={`${value}-${index}`}>
                                                            <td>
                                                                <Field
                                                                    type="number"
                                                                    name={`${value}.${index}.start`}/>
                                                                {errors[`${value}.${index}.start`] ? (
                                                                    <div
                                                                        className="errorText">{errors[`${value}.${index}.start`]}</div>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    type="number"
                                                                    name={`${value}.${index}.end`}/>
                                                                {errors[`${value}.${index}.end`] ? (
                                                                    <div
                                                                        className="errorText">{errors[`${value}.${index}.end`]}</div>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    type="color"
                                                                    style={{height: '40px'}}
                                                                    name={`${value}.${index}.color`}/>
                                                            </td>
                                                            <td>
                                                                {index === values[value].length - 1 ?
                                                                    <Button variant="outline-danger" size="sm"
                                                                            onClick={() => remove(index)}>Remove</Button> : ''}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                                </tbody>
                                            </Table>
                                            <Button variant="outline-primary" size="sm"
                                                    onClick={() => {
                                                        if (!(value in values)) {
                                                            values[value] = [];
                                                        }
                                                        push({
                                                            start: values[value].length > 0 ? values[value][values[value].length - 1].end : 0,
                                                            end: values[value].length > 0 ? values[value][values[value].length - 1].end : 0,
                                                            color: values[value].length > 0 ? values[value][values[value].length - 1].color : '#000000'
                                                        })
                                                    }}>Add
                                                interval</Button>
                                        </div>
                                    )}
                                </FieldArray>)}
                    </Formik>
                </td>
            </tr>;
        });
    };

    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <img
                        alt="ATMO"
                        src="/AtmoLOGO_white.svg"
                        height="60"
                        className="d-inline-block align-top"
                        style={{marginRight: '20px'}}
                    />
                    <div className="collapse navbar-collapse">
                        <a className="navbar-brand" href="#home">Atmocube configuration tool</a>
                    </div>
                    <Navbar.Text style={{marginRight: '10px'}}>v.{version}</Navbar.Text>
                </Container>
            </Navbar>
            <Container>
                <Formik
                    validateOnChange={false}
                    validate={values => {
                        const errors = {};
                        let error = false;
                        if (wifiEnabled) {
                            if (!values.wifi_ssid) {
                                errors.wifi_ssid = 'Wi-Fi SSID is required';
                            } else if (values.wifi_ssid.length > 32) {
                                errors.wifi_ssid = 'Maximum Wi-Fi name length is 32';
                            }
                            if (wifiEncryption === 'wpa2_3') {
                                if (!values.wifi_password) {
                                    errors.wifi_password = 'Wi-Fi password is required';
                                } else if (values.wifi_password.length < 8 || values.wifi_password.length > 63) {
                                    errors.wifi_password = 'Wi-Fi password shall be between 8 and 63 characters';
                                }
                            }
                            if (wifiDHCP === 'static') {
                                if (!values.wifi_ip || !testIPV4(values.wifi_ip)) {
                                    errors.wifi_ip = 'Please enter valid IP';
                                }
                                if (!values.wifi_mask || !testMask(values.wifi_mask)) {
                                    errors.wifi_mask = 'Please enter valid subnet mask';
                                }
                                if (!values.wifi_gateway || !testIPV4(values.wifi_gateway)) {
                                    errors.wifi_gateway = 'Please enter valid gateway IP';
                                }
                                if (!values.wifi_dns1 || !testIPV4(values.wifi_dns1)) {
                                    errors.wifi_dns1 = 'Please enter valid primary DNS';
                                }
                                if (!values.wifi_dns2 || !testIPV4(values.wifi_dns2)) {
                                    errors.wifi_dns2 = 'Please enter valid secondary DNS';
                                }
                            }
                            if (wifiEncryption === 'wpa2-enterprise' || wifiEncryption === 'wpa3-enterprise') {
                                if (!values.eap_id) {
                                    errors.eap_id = 'Please enter EAP identity';
                                }
                                if (!values.wpa_ca && (validateCAEnabled || wifiEncryption === 'wpa3-enterprise')) {
                                    errors.wpa_ca = 'Please enter WPA CA file name';
                                }
                                if (eapMethod === 'TLS') {
                                    if (!values.wpa_crt) {
                                        errors.wpa_crt = 'Please enter WPA certificate file name';
                                    }
                                    if (!values.wpa_key) {
                                        errors.wpa_key = 'Please enter WPA private key file name';
                                    }
                                } else {
                                    if (!values.wpa_username) {
                                        errors.wpa_username = 'Please enter WPA username';
                                    }
                                    if (!values.wpa_password) {
                                        errors.wpa_password = 'Please enter WPA password';
                                    }
                                }
                            }
                            if (Object.keys(errors).length > 0) {
                                error = true;
                                setTabKey('wifi');
                            }
                        }
                        if (lanEnabled && lanDHCP === 'static') {
                            if (!values.lan_ip || !testIPV4(values.lan_ip)) {
                                errors.lan_ip = 'Please enter valid IP';
                            }
                            if (!values.lan_mask || !testMask(values.lan_mask)) {
                                errors.lan_mask = 'Please enter valid subnet mask';
                            }
                            if (!values.lan_gateway || !testIPV4(values.lan_gateway)) {
                                errors.lan_gateway = 'Please enter valid gateway IP';
                            }
                            if (!values.lan_dns1 || !testIPV4(values.lan_dns1)) {
                                errors.lan_dns1 = 'Please enter valid primary DNS';
                            }
                            if (!values.lan_dns2 || !testIPV4(values.lan_dns2)) {
                                errors.lan_dns2 = 'Please enter valid secondary DNS';
                            }
                            if (!error && Object.keys(errors).length > 0) {
                                error = true;
                                setTabKey('lan');
                            }
                        }
                        if (!productionMQTTEnabled) {
                            if (!values.mqtt_host) {
                                errors.mqtt_host = 'Please enter valid MQTT host';
                            }
                            if (!values.mqtt_port || values.mqtt_port === 0) {
                                errors.mqtt_port = 'Please enter valid MQTT port';
                            }
                            if (MQTTType === 'mqtt-tcp') {
                                if (!values.mqtt_username) {
                                    errors.mqtt_username = 'Please enter MQTT username';
                                }
                                if (!values.mqtt_password) {
                                    errors.mqtt_password = 'Please enter MQTT password';
                                }
                            } else {
                                if (!values.mqtt_ca) {
                                    errors.mqtt_ca = 'Please enter MQTT root CA file name';
                                }
                                if (!values.mqtt_cert) {
                                    errors.mqtt_cert = 'Please enter MQTT certificate file name';
                                }
                                if (!values.mqtt_key) {
                                    errors.mqtt_key = 'Please enter MQTT private key file name';
                                }
                            }
                            if (!error && Object.keys(errors).length > 0) {
                                error = true;
                                setTabKey('mqtt');
                            }
                        }
                        if (rs485Enabled) {
                            if (!values.modbus_id || values.modbus_id < 1 || values.modbus_id > 247) {
                                errors.modbus_id = 'Please enter valid Modbus ID';
                            }
                            if (!error && Object.keys(errors).length > 0) {
                                error = true;
                                setTabKey('rs485');
                            }
                        }
                        if (modbusIPEnabled) {
                            if (!values.modbus_ip_port || values.modbus_ip_port < 0 || values.modbus_ip_port > 65536) {
                                errors.modbus_ip_port = 'Please enter valid Modbus IP port';
                            }
                            if (!error && Object.keys(errors).length > 0) {
                                error = true;
                                setTabKey('modbus_ip');
                            }
                        }
                        if (!productionOTAEnabled) {
                            if (!values.ota_ca) {
                                errors.ota_ca = 'Please enter OTA root CA file name';
                            }
                            if (!values.ota_url) {
                                errors.ota_url = 'Please enter valid OTA url';
                            }
                        }
                        if (!values.ota_time || !testTime(values.ota_time)) {
                            errors.ota_time = 'Please enter valid OTA time';
                        }
                        if (!error && Object.keys(errors).length > 0) {
                            error = true;
                            setTabKey('ota');
                        }
                        return errors;
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        generateConfig(values);
                        setSubmitting(false);
                    }}
                    initialValues={{
                        wifi_ssid: '',
                        wifi_password: '',
                        wifi_ip: '',
                        wifi_mask: '',
                        wifi_gateway: '',
                        wifi_dns1: '',
                        wifi_dns2: '',
                        eap_id: '',
                        wpa_ca: '',
                        wpa_crt: '',
                        wpa_key: '',
                        wpa_key_password: '',
                        wpa_username: '',
                        wpa_password: '',
                        lan_ip: '',
                        lan_mask: '',
                        lan_gateway: '',
                        lan_dns1: '',
                        lan_dns2: '',
                        mqtt_host: '',
                        mqtt_port: 0,
                        mqtt_ca: '',
                        mqtt_cert: '',
                        mqtt_key: '',
                        mqtt_username: '',
                        mqtt_password: '',
                        day1: false,
                        day2: false,
                        day3: false,
                        day4: false,
                        day5: false,
                        day6: false,
                        day7: false,
                        ota_time: '21:00',
                        ota_ca: '',
                        ota_url: '',
                        modbus_id: '',
                        modbus_ip_port: 502,
                        cube_position: 'wall',
                        temp_correction: 0.0,
                        hum_correction: 0.0,
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <Form2 onSubmit={handleSubmit}>
                            <Card className="mt-4">
                                <Card.Body>
                                    <Card.Title>Atmocube model (SKU)</Card.Title>
                                    <Card.Text>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={CUBE_CONFIGS_BASIC[0]}
                                            isClearable={false}
                                            isSearchable={true}
                                            name="model"
                                            options={CUBE_CONFIGS_BASIC}
                                            onChange={(option) => {
                                                handleLanEnabled(CONFIGS[option.value].lan);
                                                setTabKey('wifi')
                                            }}
                                        />
                                    </Card.Text>
                                    <Tabs activeKey={tabKey} id="main-tabs" className="mb-3"
                                          onSelect={(k) => setTabKey(k)}>
                                        <Tab eventKey="wifi" title="Wi-Fi">
                                            <Form2.Check
                                                inline
                                                label="Enabled"
                                                type="checkbox"
                                                defaultChecked={wifiEnabled}
                                                onChange={() => handleWifiEnabled(!wifiEnabled)}
                                                name="useWifi"/>
                                            <div style={{display: wifiEnabled ? 'block' : 'none'}}>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Wi-Fi encryption</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={WIFI_ENCRYPTION[1]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="wifi-encryption"
                                                        options={WIFI_ENCRYPTION}
                                                        onChange={(option) => {
                                                            handleWifiEncryption(option.value)
                                                        }}
                                                    />
                                                    <Form2.Text className="text-danger"
                                                                style={{display: wifiEncryption === 'wpa2-enterprise' || wifiEncryption === 'wpa3-enterprise' ? 'block' : 'none'}}
                                                    >IMPORTANT: Ensure your device is running firmware version 2.6.2.6 or later.</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>SSID</Form2.Label>
                                                    <Form2.Control
                                                        type="text"
                                                        placeholder="Wi-Fi name (SSID)"
                                                        name="wifi_ssid"
                                                        value={values.wifi_ssid}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.wifi_ssid}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.wifi_ssid}
                                                    </Form2.Control.Feedback>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2"
                                                             style={{display: wifiEncryption === 'wpa2_3' ? 'block' : 'none'}}>
                                                    <Form2.Label>Password</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Wi-Fi password"
                                                                   name="wifi_password"
                                                                   value={values.wifi_password}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.wifi_password}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.wifi_password}
                                                    </Form2.Control.Feedback>
                                                </Form2.Group>
                                                <div style={{display: wifiEncryption === 'wpa2-enterprise' || wifiEncryption === 'wpa3-enterprise' ? 'block' : 'none'}}>
                                                    <Form2.Group className="mt-2">
                                                        <Form2.Label>EAP ID</Form2.Label>
                                                        <Form2.Control type="text"
                                                                       placeholder="EAP ID"
                                                                       name="eap_id"
                                                                       value={values.eap_id}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.eap_id}
                                                        />
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2" style={{display: wifiEncryption === 'wpa2-enterprise' ? 'block' : 'none'}}>
                                                        <Form2.Check
                                                            inline
                                                            label="Validate CA"
                                                            type="checkbox"
                                                            defaultChecked={validateCAEnabled}
                                                            onChange={() => handleValidateCAEnabled(!validateCAEnabled)}
                                                            name="validate_ca"/>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-3" style={{display: validateCAEnabled || wifiEncryption === 'wpa3-enterprise' ? 'block' : 'none'}}>
                                                        <Form2.Label>Root CA file name</Form2.Label>
                                                        <Form2.Control type="text"
                                                                       placeholder="Root CA file name"
                                                                       name="wpa_ca"
                                                                       value={values.wpa_ca}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.wpa_ca}
                                                        />
                                                        <Form2.Control.Feedback type="invalid">
                                                            {errors.wpa_ca}
                                                        </Form2.Control.Feedback>
                                                        <Form2.Text className="text-muted">Example: ca.der</Form2.Text>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2" style={{display: wifiEncryption === 'wpa3-enterprise' ? 'block' : 'none'}}>
                                                        <Form2.Check
                                                            inline
                                                            label="WPA3 192-bit"
                                                            type="checkbox"
                                                            defaultChecked={wpa3_192Enabled}
                                                            onChange={() => handleWpa3_192Enabled(!wpa3_192Enabled)}
                                                            name="wpa3_192"/>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2">
                                                        <Form2.Label>EAP method</Form2.Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            defaultValue={EAP_METHOD[0]}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            name="eap-method"
                                                            options={EAP_METHOD}
                                                            onChange={(option) => {
                                                                handleEapMethod(option.value)
                                                            }}
                                                        />
                                                    </Form2.Group>
                                                    <div style={{display: eapMethod === 'TLS' ? 'block' : 'none'}}>
                                                        <Form2.Group className="mt-3">
                                                            <Form2.Label>Certificate file name</Form2.Label>
                                                            <Form2.Control type="text"
                                                                           placeholder="Certificate file name"
                                                                           name="wpa_crt"
                                                                           value={values.wpa_crt}
                                                                           onChange={handleChange}
                                                                           isInvalid={!!errors.wpa_crt}
                                                            />
                                                            <Form2.Control.Feedback type="invalid">
                                                                {errors.wpa_crt}
                                                            </Form2.Control.Feedback>
                                                            <Form2.Text className="text-muted">Example: client.crt</Form2.Text>
                                                        </Form2.Group>
                                                        <Form2.Group className="mt-3">
                                                            <Form2.Label>Private key file name</Form2.Label>
                                                            <Form2.Control type="text"
                                                                           placeholder="Private key file name"
                                                                           name="wpa_key"
                                                                           value={values.wpa_key}
                                                                           onChange={handleChange}
                                                                           isInvalid={!!errors.wpa_key}
                                                            />
                                                            <Form2.Control.Feedback type="invalid">
                                                                {errors.wpa_key}
                                                            </Form2.Control.Feedback>
                                                            <Form2.Text className="text-muted">Example: client.key</Form2.Text>
                                                        </Form2.Group>
                                                        <Form2.Group className="mt-3">
                                                            <Form2.Label>Private key file password</Form2.Label>
                                                            <Form2.Control type="text"
                                                                           placeholder="Private key file password"
                                                                           name="wpa_key_password"
                                                                           value={values.wpa_key_password}
                                                                           onChange={handleChange}
                                                                           isInvalid={!!errors.wpa_key_password}
                                                            />
                                                        </Form2.Group>
                                                    </div>
                                                    <div style={{display: eapMethod !== 'TLS' ? 'block' : 'none'}}>
                                                        <Form2.Group className="mt-3">
                                                            <Form2.Label>Username</Form2.Label>
                                                            <Form2.Control type="text"
                                                                           placeholder="Username"
                                                                           name="wpa_username"
                                                                           value={values.wpa_username}
                                                                           onChange={handleChange}
                                                                           isInvalid={!!errors.wpa_username}
                                                            />
                                                            <Form2.Control.Feedback type="invalid">
                                                                {errors.wpa_username}
                                                            </Form2.Control.Feedback>
                                                        </Form2.Group>
                                                        <Form2.Group className="mt-3">
                                                            <Form2.Label>Password</Form2.Label>
                                                            <Form2.Control type="text"
                                                                           placeholder="Password"
                                                                           name="wpa_password"
                                                                           value={values.wpa_password}
                                                                           onChange={handleChange}
                                                                           isInvalid={!!errors.wpa_password}
                                                            />
                                                            <Form2.Control.Feedback type="invalid">
                                                                {errors.wpa_password}
                                                            </Form2.Control.Feedback>
                                                        </Form2.Group>
                                                    </div>
                                                    <Form2.Group className="mt-2" style={{display: eapMethod === 'TTLS' ? 'block' : 'none'}}>
                                                        <Form2.Label>TTLS phase 2</Form2.Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            defaultValue={TTLS_PHASE2[0]}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            name="ttls-phase2"
                                                            options={TTLS_PHASE2}
                                                            onChange={(option) => {
                                                                handleTtlsPhase2(option.value)
                                                            }}
                                                        />
                                                    </Form2.Group>
                                                </div>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Configure IP</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={DHCP_OPTIONS[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="wifi-dhcp"
                                                        options={DHCP_OPTIONS}
                                                        onChange={(option) => {
                                                            handleWifiDHCP(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <div className="mt-3"
                                                     style={{display: wifiDHCP === 'auto' ? 'none' : 'block'}}>
                                                    <Form2.Group>
                                                        <Form2.Control type="text"
                                                                       placeholder="IP"
                                                                       name="wifi_ip"
                                                                       value={values.wifi_ip}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.wifi_ip}/>
                                                        <Form2.Control.Feedback type="invalid">
                                                            {errors.wifi_ip}
                                                        </Form2.Control.Feedback>
                                                        <Form2.Text className="text-muted">Example:
                                                            192.168.0.10</Form2.Text>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2">
                                                        <Form2.Control type="text"
                                                                       placeholder="Mask"
                                                                       name="wifi_mask"
                                                                       value={values.wifi_mask}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.wifi_mask}/>
                                                        <Form2.Control.Feedback type="invalid">
                                                            {errors.wifi_mask}
                                                        </Form2.Control.Feedback>
                                                        <Form2.Text className="text-muted">Example:
                                                            255.255.255.0</Form2.Text>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2">
                                                        <Form2.Control type="text"
                                                                       placeholder="Gateway IP"
                                                                       name="wifi_gateway"
                                                                       value={values.wifi_gateway}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.wifi_gateway}
                                                        />
                                                        <Form2.Control.Feedback type="invalid">
                                                            {errors.wifi_gateway}
                                                        </Form2.Control.Feedback>
                                                        <Form2.Text className="text-muted">Example:
                                                            192.168.0.1</Form2.Text>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2">
                                                        <Form2.Control type="text"
                                                                       placeholder="Primary DNS"
                                                                       name="wifi_dns1"
                                                                       value={values.wifi_dns1}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.wifi_dns1}
                                                        />
                                                        <Form2.Control.Feedback type="invalid">
                                                            {errors.wifi_dns1}
                                                        </Form2.Control.Feedback>
                                                        <Form2.Text className="text-muted">Example: 8.8.8.8</Form2.Text>
                                                    </Form2.Group>
                                                    <Form2.Group className="mt-2">
                                                        <Form2.Control type="text"
                                                                       placeholder="Secondary DNS"
                                                                       name="wifi_dns2"
                                                                       value={values.wifi_dns2}
                                                                       onChange={handleChange}
                                                                       isInvalid={!!errors.wifi_dns2}
                                                        />
                                                        <Form2.Control.Feedback type="invalid">
                                                            {errors.wifi_dns2}
                                                        </Form2.Control.Feedback>
                                                        <Form2.Text className="text-muted">Example: 8.8.4.4</Form2.Text>
                                                    </Form2.Group>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="lan" title="LAN" disabled={!lanEnabled}>
                                            <Form2.Group className="mt-2">
                                                <Form2.Label>Configure IP</Form2.Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={DHCP_OPTIONS[0]}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    name="lan-dhcp"
                                                    options={DHCP_OPTIONS}
                                                    onChange={(option) => {
                                                        handleLanDHCP(option.value)
                                                    }}
                                                />
                                            </Form2.Group>
                                            <div className="mt-3"
                                                 style={{display: lanDHCP === 'auto' ? 'none' : 'block'}}>
                                                <Form2.Group>
                                                    <Form2.Control type="text"
                                                                   placeholder="IP"
                                                                   name="lan_ip"
                                                                   value={values.lan_ip}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.lan_ip}/>
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.lan_ip}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example:
                                                        192.168.0.10</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Control type="text"
                                                                   placeholder="Mask"
                                                                   name="lan_mask"
                                                                   value={values.lan_mask}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.lan_mask}/>
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.lan_mask}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example:
                                                        255.255.255.0</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Control type="text"
                                                                   placeholder="Gateway IP"
                                                                   name="lan_gateway"
                                                                   value={values.lan_gateway}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.lan_gateway}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.lan_gateway}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example:
                                                        192.168.0.1</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Control type="text"
                                                                   placeholder="Primary DNS"
                                                                   name="lan_dns1"
                                                                   value={values.lan_dns1}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.lan_dns1}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.lan_dns1}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: 8.8.8.8</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Control type="text"
                                                                   placeholder="Secondary DNS"
                                                                   name="lan_dns2"
                                                                   value={values.lan_dns2}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.lan_dns2}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.lan_dns2}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: 8.8.4.4</Form2.Text>
                                                </Form2.Group>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="celular" title="Cellular" disabled>
                                        </Tab>
                                        <Tab eventKey="ntp" title="NTP">
                                            <Form2.Label>NTP servers</Form2.Label>
                                            <CreatableSelect
                                                isMulti
                                                onChange={handleNTPChange}
                                                defaultValue={[ntpOptions[0], ntpOptions[1], ntpOptions[2], ntpOptions[3]]}
                                                options={ntpOptions}
                                            />
                                        </Tab>
                                        <Tab eventKey="security" title="Security">
                                            <div key={`inline-checkbox`} className="mb-3">
                                                <Form2.Check
                                                    inline
                                                    label="USB enabled"
                                                    type="checkbox"
                                                    defaultChecked={usbEnabled}
                                                    onChange={() => handleUsbEnabled(!usbEnabled)}
                                                    id={`enabled-usb`}/>
                                                <Form2.Check
                                                    inline
                                                    label="USB data storage enabled"
                                                    type="checkbox"
                                                    defaultChecked={usbDataStorageEnabled}
                                                    onChange={() => handleUsbDataStorageEnabled(!usbDataStorageEnabled)}
                                                    id={`enabled-data-usb`}/>
                                                <Form2.Check
                                                    inline
                                                    label="Reset with main button"
                                                    type="checkbox"
                                                    defaultChecked={mainEnabled}
                                                    onChange={() => handleMainEnabled(!mainEnabled)}
                                                    id={`enabled-main`}/>
                                                <Form2.Check
                                                    inline
                                                    label="BLE advertising"
                                                    type="checkbox"
                                                    defaultChecked={bleEnabled}
                                                    onChange={() => handleBleEnabled(!bleEnabled)}
                                                    id={`enabled-ble-adv`}/>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="leds" title="LEDs">
                                            <Form2.Check
                                                inline
                                                label="Custom LED configuration"
                                                type="checkbox"
                                                defaultChecked={customLedEnabled}
                                                onChange={() => handleCustomLedEnabled(!customLedEnabled)}
                                                name="custom_led_enable"/>
                                            <div style={{display: customLedEnabled ? 'block' : 'none'}}>
                                                <Form2.Group className="col-3 mt-2">
                                                    <Form2.Label>TVOC configuration</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={CUBE_CONFIGS_TVOC[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="tvoc_config"
                                                        options={CUBE_CONFIGS_TVOC}
                                                        onChange={(option) => {
                                                            configLED(option.value, ch2oEnabled)
                                                            handleVOCtype(option.value);
                                                            var ch2o = document.getElementsByName('model_ch2o')[0];
                                                            if (option.value === 'tvoc-index') {
                                                                ch2o.checked = true
                                                                ch2o.disabled = true
                                                                handleCh2oEnabled(true)
                                                            } else {
                                                                ch2o.disabled = false
                                                            }
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <Form2.Check
                                                    inline
                                                    label="CH2O sensor"
                                                    type="checkbox"
                                                    disabled={true}
                                                    className="mt-4"
                                                    style={{display: VOCtype === 'tvoc-ppm' ? 'block' : 'none'}}
                                                    defaultChecked={ch2oEnabled}
                                                    name="model_ch2o"
                                                    onChange={(option) => {
                                                        handleCh2oEnabled(!ch2oEnabled)
                                                        configLED(VOCtype, !ch2oEnabled)
                                                    }}/>
                                                <Col className="col-6 mt-4">
                                                    <Table bordered className="text-center">
                                                        <tbody>
                                                        <tr>
                                                            <td width={'10%'}>{ledArray[0]}</td>
                                                            <td width={'10%'}>{ledArray[1]}</td>
                                                            <td width={'10%'}>{ledArray[2]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{ledArray[3]}</td>
                                                            <td>{ledArray[4]}</td>
                                                            <td>{ledArray[5]}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{ledArray[6]}</td>
                                                            <td>{ledArray[7]}</td>
                                                            <td></td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                                <Table bordered responsive style={{minWidth: '450px'}}>
                                                    <tbody>
                                                    {ledValues()}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="mqtt" title="MQTT">
                                            <Form2.Check
                                                inline
                                                label="Enabled"
                                                type="checkbox"
                                                defaultChecked={mqttEnabled}
                                                onChange={() => handleMQTTEnabled(!mqttEnabled)}
                                                name="mqtt_enable"/>
                                            <Form2.Check
                                                inline
                                                label="Production MQTT"
                                                type="checkbox"
                                                style={{display: mqttEnabled ? 'block' : 'none'}}
                                                defaultChecked={productionMQTTEnabled}
                                                onChange={() => handleProductionMQTTEnabled(!productionMQTTEnabled)}
                                                id={`production-mqtt`}/>
                                            <div className="mt-2"
                                                 style={{display: mqttEnabled && !productionMQTTEnabled ? 'block' : 'none'}}>
                                                <Form2.Group>
                                                    <Form2.Label>MQTT host</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Enter MQTT host"
                                                                   name="mqtt_host"
                                                                   value={values.mqtt_host}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.mqtt_host}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_host}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: 127.0.0.1</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>MQTT port</Form2.Label>
                                                    <Form2.Control type="number"
                                                                   placeholder="Port"
                                                                   name="mqtt_port"
                                                                   value={values.mqtt_port}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.mqtt_port}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_port}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text
                                                        className="text-muted">Example: {MQTTType === 'mqtt-tcp' ? '1883' : '8883'}</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>MQTT security</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={MQTT_OPTIONS[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="mqtt-options"
                                                        options={MQTT_OPTIONS}
                                                        onChange={(option) => {
                                                            handleMQTTType(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <Form2.Group className="mt-3"
                                                             style={{display: MQTTType === 'mqtt-tcp' ? 'block' : 'none'}}>
                                                    <Form2.Label>Username</Form2.Label>
                                                    <Form2.Control
                                                        type="text"
                                                        placeholder="Username"
                                                        name="mqtt_username"
                                                        value={values.mqtt_username}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mqtt_username}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_username}
                                                    </Form2.Control.Feedback>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2"
                                                             style={{display: MQTTType === 'mqtt-tcp' ? 'block' : 'none'}}>
                                                    <Form2.Label>Password</Form2.Label>
                                                    <Form2.Control
                                                        type="password"
                                                        placeholder="Password"
                                                        name="mqtt_password"
                                                        value={values.mqtt_password}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.mqtt_password}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_password}
                                                    </Form2.Control.Feedback>
                                                </Form2.Group>
                                                <Form2.Group className="mt-3"
                                                             style={{display: MQTTType === 'mqtt-tls' ? 'block' : 'none'}}>
                                                    <Form2.Label>Root CA file name</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Root CA file name"
                                                                   name="mqtt_ca"
                                                                   value={values.mqtt_ca}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.mqtt_ca}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_ca}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: ca.crt</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2"
                                                             style={{display: MQTTType === 'mqtt-tls' ? 'block' : 'none'}}>
                                                    <Form2.Label>Certificate file name</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Certificate file name"
                                                                   name="mqtt_cert"
                                                                   value={values.mqtt_cert}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.mqtt_cert}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_cert}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: client.crt</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2"
                                                             style={{display: MQTTType === 'mqtt-tls' ? 'block' : 'none'}}>
                                                    <Form2.Label>Private key file name</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Private key name"
                                                                   name="mqtt_key"
                                                                   value={values.mqtt_key}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.mqtt_key}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.mqtt_key}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: client.key</Form2.Text>
                                                </Form2.Group>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="ota" title="OTA">
                                            <Form2.Check
                                                inline
                                                label="Production OTA"
                                                type="checkbox"
                                                defaultChecked={productionOTAEnabled}
                                                onChange={() => handleProductionOTAEnabled(!productionOTAEnabled)}
                                                id={`production-ota`}/>
                                            <div className="mt-2"
                                                 style={{display: productionOTAEnabled ? 'none' : 'block'}}>
                                                <Form2.Group>
                                                    <Form2.Label>OTA URL</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Enter OTA configuration URL"
                                                                   name="ota_url"
                                                                   value={values.ota_url}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.ota_url}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.ota_url}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example:
                                                        https://somehost.com/config</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Root CA file name</Form2.Label>
                                                    <Form2.Control type="text"
                                                                   placeholder="Root CA file name"
                                                                   name="ota_ca"
                                                                   value={values.ota_ca}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.ota_ca}
                                                    />
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.ota_ca}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: root_ca.crt</Form2.Text>
                                                </Form2.Group>
                                            </div>
                                            <div className="mt-2">
                                                <Form2.Label>Update schedule</Form2.Label>
                                                <div key={`inline-checkbox`}>
                                                    <Form2.Check
                                                        inline
                                                        label="Monday"
                                                        type="checkbox"
                                                        value={values.day1}
                                                        onChange={handleChange}
                                                        name="day1"/>
                                                    <Form2.Check
                                                        inline
                                                        label="Tuesday"
                                                        type="checkbox"
                                                        value={values.day2}
                                                        onChange={handleChange}
                                                        name="day2"/>
                                                    <Form2.Check
                                                        inline
                                                        label="Wednesday"
                                                        type="checkbox"
                                                        value={values.day3}
                                                        onChange={handleChange}
                                                        name="day3"/>
                                                    <Form2.Check
                                                        inline
                                                        label="Thursday"
                                                        type="checkbox"
                                                        value={values.day4}
                                                        onChange={handleChange}
                                                        name="day4"/>
                                                    <Form2.Check
                                                        inline
                                                        label="Friday"
                                                        type="checkbox"
                                                        value={values.day5}
                                                        onChange={handleChange}
                                                        name="day5"/>
                                                    <Form2.Check
                                                        inline
                                                        label="Saturday"
                                                        type="checkbox"
                                                        value={values.day6}
                                                        onChange={handleChange}
                                                        name="day6"/>
                                                    <Form2.Check
                                                        inline
                                                        label="Sunday"
                                                        type="checkbox"
                                                        value={values.day7}
                                                        onChange={handleChange}
                                                        name="day7"/>
                                                </div>
                                            </div>
                                            <Form2.Group className="mt-2">
                                                <Form2.Label>Update check time</Form2.Label>
                                                <Form2.Control type="text"
                                                               placeholder="Update check time"
                                                               name="ota_time"
                                                               value={values.ota_time}
                                                               onChange={handleChange}
                                                               isInvalid={!!errors.ota_time}/>
                                                <Form2.Control.Feedback type="invalid">
                                                    {errors.ota_time}
                                                </Form2.Control.Feedback>
                                                <Form2.Text className="text-muted">Example: 22:00 (time in 24h format,
                                                    UTC)</Form2.Text>
                                            </Form2.Group>
                                        </Tab>
                                        <Tab eventKey="rs485" title="RS-485">
                                            <Form2.Check
                                                inline
                                                label="Enabled"
                                                type="checkbox"
                                                defaultChecked={rs485Enabled}
                                                onChange={() => handleRS485Enabled(!rs485Enabled)}
                                                name="rs485_enable"/>
                                            <div className="mt-2" style={{display: rs485Enabled ? 'block' : 'none'}}>
                                                <Form2.Group>
                                                    <Form2.Label>Protocol</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={RS485_OPTIONS[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="rs485-options"
                                                        options={RS485_OPTIONS}
                                                        onChange={(option) => {
                                                            handleRS485Option(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Device ID</Form2.Label>
                                                    <Form2.Control type="number"
                                                                   placeholder="Device ID"
                                                                   name="modbus_id"
                                                                   value={values.modbus_id}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.modbus_id}/>
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.modbus_id}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: 1 (1 to
                                                        247)</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Baud Rate</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={RS485_BAUD_RATE_OPTIONS[7]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="rs485-baud-rate"
                                                        options={RS485_BAUD_RATE_OPTIONS}
                                                        onChange={(option) => {
                                                            handleRS485BaudRate(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Data Bits</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={RS485_DATA_BITS_OPTIONS[1]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="rs485-data-bits"
                                                        options={RS485_DATA_BITS_OPTIONS}
                                                        onChange={(option) => {
                                                            handleRS485DataBits(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Parity</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={RS485_PARITY_OPTIONS[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="rs485-parity"
                                                        options={RS485_PARITY_OPTIONS}
                                                        onChange={(option) => {
                                                            handleRS485Parity(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Stop bits</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={RS485_STOP_BITS_OPTIONS[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="rs485-stop-bits"
                                                        options={RS485_STOP_BITS_OPTIONS}
                                                        onChange={(option) => {
                                                            handleRS485StopBits(option.value)
                                                        }}
                                                    />
                                                </Form2.Group>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="modbus_ip" title="Modbus IP">
                                            <Form2.Check
                                                inline
                                                label="Enabled"
                                                type="checkbox"
                                                defaultChecked={modbusIPEnabled}
                                                onChange={() => handleModbusIPEnabled(!modbusIPEnabled)}
                                                name="modbus_ip_enable"/>
                                            <div className="mt-2" style={{display: modbusIPEnabled ? 'block' : 'none'}}>
                                                <Form2.Group>
                                                    <Form2.Label>Modbus IP port</Form2.Label>
                                                    <Form2.Control type="number"
                                                                   placeholder="Modbus IP port"
                                                                   name="modbus_ip_port"
                                                                   value={values.modbus_ip_port}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.modbus_ip_port}/>
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.modbus_ip_port}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: 502 (0 to
                                                        65535)</Form2.Text>
                                                </Form2.Group>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="other" title="Other">
                                            <Form2.Check
                                                inline
                                                label="Humidity/temperature correction"
                                                type="checkbox"
                                                defaultChecked={compensationEnabled}
                                                onChange={() => handleCompensationEnabled(!compensationEnabled)}
                                                name="compensation_enable"/>
                                            <div className="mt-2" style={{display: compensationEnabled ? 'block' : 'none'}}>
                                                <Form2.Text className="text-danger">IMPORTANT: Ensure your device is running firmware version 2.6.2.6 or later.</Form2.Text>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Atmocube position</Form2.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={CUBE_POSITIONS[0]}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        name="cube-position"
                                                        options={CUBE_POSITIONS}
                                                        onChange={(option) => {
                                                            handleCubePosition(option.value)
                                                        }}
                                                    />
                                                    <Form2.Text className="text-muted">Expect minor variations in temperature between the table and the wall, with the table potentially exhibiting a slightly higher reading.</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Temperature correction</Form2.Label>
                                                    <Form2.Control type="number"
                                                                   step={0.1}
                                                                   placeholder="0.0"
                                                                   name="temp_correction"
                                                                   value={values.temp_correction}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.temp_correction}/>
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.temp_correction}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: 1.0</Form2.Text>
                                                </Form2.Group>
                                                <Form2.Group className="mt-2">
                                                    <Form2.Label>Humidity correction</Form2.Label>
                                                    <Form2.Control type="number"
                                                                   placeholder="0.0"
                                                                   step={0.1}
                                                                   name="hum_correction"
                                                                   value={values.hum_correction}
                                                                   onChange={handleChange}
                                                                   isInvalid={!!errors.hum_correction}/>
                                                    <Form2.Control.Feedback type="invalid">
                                                        {errors.hum_correction}
                                                    </Form2.Control.Feedback>
                                                    <Form2.Text className="text-muted">Example: -5.0</Form2.Text>
                                                </Form2.Group>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                    <Button variant="info" type="submit" className="mt-4" disabled={isSubmitting}>Generate
                                        config</Button>
                                </Card.Body>
                                <Card.Footer className="text-muted">Copyright &copy; 2024 Atmotech Inc.</Card.Footer>
                            </Card>
                        </Form2>)}
                </Formik>
            </Container>
        </div>
    );
}

export default App;
